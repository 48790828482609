import { useEffect, useState } from 'react';
import { CategoryTree } from './category-tree';
import { ICategory } from './category-type';
import useCategoryState from './hooks/useCategoryState';
import MoneyTransactionsList from '../money-transactions/list/money-transactions-list';
import useMoneyEntities from '../money-entity/hooks/useMoneyEntities';
import { IMoneyEntity } from '../money-entity/money-entity-type';
import { LabelDropDown } from '../../../_components/reuse/LabelDropDown';
import useMoneyAccounts from '../money-account/hooks/useMoneyAccounts';
import useMoneyTransactionsFilter from '../money-transactions/hooks/useMoneyTransactionsFilter';
import { useNavigate } from 'react-router-dom';
import MoneyFilter from '../money-filter/money-filter';
import { useBetween } from '../../../hooks/useBetween';
import useShortcut from './shortcut/useShortcut';
import { SHORTCUT_ACTIONS } from './constants';
import { TabView, TabPanel } from 'primereact/tabview';
import useTranslations from '../../translations/useTranslations';
import MoneyChartGraph from './charts/money-chart-graph';
import { useUserMethods } from '../../user/useUserMethods';
import { EntityUser } from '../../user/types';

export const Categories = () => {
  //#region Hooks
  const { currentTranslation } = useBetween(useTranslations);
  const {
    newCategory,
    setNewCategory,
    saveCategory,
    getCategories,
    categoryTree,
    categories,
    aggregateCategories,
    // aggregateAmountByCategory
    callAggregateAmountByCategory,
  } = useBetween(useCategoryState);
  const { getUsers } = useUserMethods();

  const navigate = useNavigate();

  const { moneyEntities, selectedMoneyEntity, setSelectedMoneyEntity } =
    useBetween(useMoneyEntities);
  const { accounts } = useBetween(useMoneyAccounts);

  const moneyEntitiesList: IMoneyEntity[] = [
    { _id: '', name: '--ALL--', date: 0, description: '' },
    ...(moneyEntities || []),
  ];

  const { filterBy } = useBetween(useMoneyTransactionsFilter);

  //#endregion

  //#region States
  const [message, setMessage] = useState('');
  const [users, setUsers] = useState<EntityUser[] | null>(null);
  //#endregion

  //#region Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getCategories(selectedMoneyEntity);
        const entityId = selectedMoneyEntity?._id || '';
        const val = await getUsers(entityId);
        setUsers(val);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedMoneyEntity]);

  useEffect(() => {
    const entityId = selectedMoneyEntity?._id || '';
    const filterValue = filterBy || {};
    callAggregateAmountByCategory(entityId, filterValue);
  }, [filterBy]);

  useEffect(() => {
    // return;
    if (!accounts) {
      return;
    }

    if (!accounts || accounts.length === 0) {
      setMessage('Va rugam adaugati conturile necesare');
      setTimeout(() => {
        navigate('/accounts');
      }, 2000);
    }
  }, [accounts]);
  //#endregion

  //#region Functions
  const executeSaveCategory = (val: ICategory) => {
    saveCategory(val, selectedMoneyEntity).then((response: any) => {
      setNewCategory(null);
    });
    setNewCategory(null);
  };

  const onNodeSelected = (node: any) => {
    console.log(node);
  };
  //#endregion

  //#region Rendering
  // const renderCategoryDialog = () => {
  //   return newCategory ? (
  //     <DialogWrapper
  //       header="Date categorie"
  //       visible={newCategory !== null}
  //       // style={{ width: "50vw" }}
  //       onHide={() => setNewCategory(null)}
  //     >
  //       <AddEditCategory
  //         category={newCategory}
  //         onSave={executeSaveCategory}
  //         onCancel={() => setNewCategory(null)}
  //       ></AddEditCategory>
  //     </DialogWrapper>
  //   ) : null;
  // };

  return (
    <div className="fcenter1">
      {/* {JSON.stringify(filterBy, null, 2)} */}
      {/* {JSON.stringify(aggregationFilterBy, null, 2)} */}
      {message && <div className="error fcenter">{message}</div>}
      {/* {JSON.stringify(accounts, null, 2)} */}
      {/* {JSON.stringify(moneyEntities)} */}
      {moneyEntities && moneyEntities.length > 0 && (
        <div className="fcenter">
          <LabelDropDown
            label="Entitati"
            onChange={(item) => {
              console.log(item);

              setSelectedMoneyEntity(item);
            }}
            options={moneyEntitiesList}
            value={selectedMoneyEntity}
            placeholder="Selecteaza"
            optionLabel="name"
            className=""
          ></LabelDropDown>
        </div>
      )}

      {/* {selectedCategory && (
        <div className="fcenter mt10">
          {renderCategoryDialog()}
          <MyButton
            onClick={() => setNewCategory(defaultCategory())}
            text="Adaugare Categorie"
          ></MyButton>
        </div>
      )} */}

      <div className="fcenter">
        <CategoryTree
          categoryTree={categoryTree}
          onNodeSelected={onNodeSelected}
        ></CategoryTree>
      </div>
      <MoneyFilter users={users || []}></MoneyFilter>

      <div className="fcenter mt15">
        <TabView>
          <TabPanel header={currentTranslation.MONEY_TRANSACTIONS.transactions}>
            <div className="fcenter">
              <MoneyTransactionsList
                users={users || []}
              ></MoneyTransactionsList>
            </div>
          </TabPanel>
          <TabPanel header={currentTranslation.Charts}>
            <div className="fcenter" style={{ width: '100%' }}>
              <MoneyChartGraph
                categories={categories}
                entityId={selectedMoneyEntity?._id}
                filterBy={filterBy}
                aggregateCategories={aggregateCategories}
              ></MoneyChartGraph>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
  //#endregion
};
