const TR = {
  INVALID_PASSWORD: 'Invalid password',
  USER_NOT_FOUND: 'User not found',
  INVALID_EMAIL: 'Invalid email',

  HEADERS: {
    User: 'User',
    Category: 'Categorie',
    Amount: 'Amount',
    Date: 'Date',
    Description: 'Description',
    Name: 'Name',
    Events: 'Events',
    Invitations: 'Invitations',
    Accounts: 'Accounts',
    Money_aggregator: 'Money',
    Logout: 'Logout',
    Login: 'Login',
  },
  ACCOUNT: {
    rememberUser: 'Remember user',
    navigateToCreateAccount: 'Navigate to create account',
    forgotPassword: 'Forgot password',
    email: 'Email',
    password: 'Password',
    forgotPasswordMessage:
      'Enter your email and we will send you a link to reset your password',
    resetPassword: 'Reset password',
    navigateToLogin: 'Navigate to login',
    nick: 'Nick',
    termsAndConditions: 'Terms',
    createUser: 'Create account',
    addAccount: 'Add account',
    editAccount: 'Edit account',
    deleteAccount: 'Delete account',
    invalidName: 'Invalid name',
    account: 'Account',
  },
  MONEY_TRANSACTIONS: {
    transactions: 'Transactions',
    addTransaction: 'Add transaction',
    editTransaction: 'Edit tranzaction',
    deleteTransaction: 'Delete Transaction',
    transactionType: 'Transaction type',
    users: 'Users',
  },
  CATEGORIES: {
    categories: 'Categories',
    addCategory: 'Add category',
    editCategory: 'Edit category',
    deleteCategory: 'Delete category',
  },
  SAVE: 'Save',
  CANCEL: 'Cancel',
  ERROR: 'Error',
  SUCCESS: 'Success',
  LOADING: 'Loading',
  WARNING: 'Warning',
  UPDATE: 'Update',
  Charts: 'Charts',
};

export default TR;
