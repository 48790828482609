export const SvgCompany = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 -8 1040 1040"
      version="1.1"
      color="blue"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1011.952941 445.741176H36.141176L512.401569 16.062745z"
        fill="#EDF2F8"
      />
      <path
        d="M1011.952941 461.803922H36.141176c-6.425098 0-12.850196-4.015686-15.259607-10.440785-2.409412-6.425098-0.803137-12.850196 4.015686-17.669019L501.157647 4.015686c5.621961-5.621961 15.259608-5.621961 20.881569 0L1022.393725 433.694118c4.818824 4.015686 7.228235 11.243922 4.818824 17.669019-2.409412 6.425098-8.83451 10.440784-15.259608 10.440785z m-934.048627-32.125491h890.679215L512.401569 37.747451 77.904314 429.678431z"
        fill="#131313"
      />
      <path
        d="M518.826667 221.665882m-77.904314 0a77.904314 77.904314 0 1 0 155.808627 0 77.904314 77.904314 0 1 0-155.808627 0Z"
        fill="#C2CDDC"
      />
      <path
        d="M518.826667 314.829804c-51.400784 0-93.967059-41.763137-93.967059-93.967059s41.763137-93.967059 93.967059-93.967059c51.400784 0 93.967059 41.763137 93.967058 93.967059S570.227451 314.829804 518.826667 314.829804z m0-155.00549c-33.731765 0-61.841569 27.306667-61.841569 61.841568s27.306667 61.841569 61.841569 61.841569c33.731765 0 61.841569-27.306667 61.841568-61.841569s-28.109804-61.841569-61.841568-61.841568z"
        fill="#131313"
      />
      <path
        d="M38.550588 445.741176h962.158432c12.850196 0 22.487843 10.440784 22.487843 22.487844v20.881568c0 12.850196-10.440784 22.487843-22.487843 22.487843H38.550588c-12.850196 0-22.487843-10.440784-22.487843-22.487843v-20.881568c0-12.047059 10.440784-22.487843 22.487843-22.487844z"
        fill="#FFFFFF"
      />
      <path
        d="M1001.512157 528.464314H38.550588C16.865882 528.464314 0 510.795294 0 489.913725v-20.881568c0-21.684706 17.66902-38.550588 38.550588-38.550588h962.158432c21.684706 0 38.550588 17.66902 38.550588 38.550588V489.913725c0.803137 20.881569-16.865882 38.550588-37.747451 38.550589zM38.550588 461.803922c-4.015686 0-6.425098 3.212549-6.425098 6.425098v20.881568c0 4.015686 3.212549 6.425098 6.425098 6.425098h962.158432c4.015686 0 6.425098-3.212549 6.425098-6.425098v-20.881568c0-4.015686-3.212549-6.425098-6.425098-6.425098H38.550588z"
        fill="#131313"
      />
      <path
        d="M158.218039 511.598431h131.71451v40.156863c0 12.850196-10.440784 22.487843-22.487843 22.487843H181.50902c-12.850196 0-22.487843-10.440784-22.487844-22.487843v-40.156863h-0.803137z"
        fill="#C2CDDC"
      />
      <path
        d="M267.444706 591.10902H181.50902c-21.684706 0-38.550588-17.66902-38.550589-38.550589v-40.156862c0-8.83451 7.228235-16.062745 16.062745-16.062745h131.71451c8.83451 0 16.062745 7.228235 16.062745 16.062745v40.156862c-0.803137 20.881569-17.66902 38.550588-39.353725 38.550589z m-93.163922-63.447844v24.094118c0 4.015686 3.212549 6.425098 6.425098 6.425098h86.738824c4.015686 0 6.425098-3.212549 6.425098-6.425098v-24.094118H174.280784z"
        fill="#131313"
      />
      <path
        d="M181.50902 816.790588h86.738823c12.850196 0 22.487843 10.440784 22.487843 22.487843v40.156863H158.218039v-40.156863c0-12.850196 10.440784-22.487843 23.290981-22.487843z"
        fill="#C2CDDC"
      />
      <path
        d="M289.932549 895.498039H158.218039c-8.83451 0-16.062745-7.228235-16.062745-16.062745v-40.156863c0-21.684706 17.66902-38.550588 38.550588-38.550588h86.738824c21.684706 0 38.550588 17.66902 38.550588 38.550588v40.156863c0 8.83451-6.425098 16.062745-16.062745 16.062745z m-115.651765-32.12549h99.58902v-24.094118c0-4.015686-3.212549-6.425098-6.425098-6.425098H181.50902c-4.015686 0-6.425098 3.212549-6.425098 6.425098v24.094118z"
        fill="#131313"
      />
      <path
        d="M191.949804 575.046275h64.25098v241.744313h-64.25098z"
        fill="#F3F3F3"
      />
      <path
        d="M256.200784 832.853333h-64.25098c-8.83451 0-16.062745-7.228235-16.062745-16.062745V575.046275c0-8.83451 7.228235-16.062745 16.062745-16.062746h64.25098c8.83451 0 16.062745 7.228235 16.062745 16.062746v241.744313c0 8.83451-7.228235 16.062745-16.062745 16.062745z m-48.188235-32.12549h32.12549V591.10902h-32.12549v209.618823z"
        fill="#131313"
      />
      <path
        d="M348.561569 511.598431h131.714509v40.156863c0 12.850196-10.440784 22.487843-22.487843 22.487843H371.049412c-12.850196 0-22.487843-10.440784-22.487843-22.487843v-40.156863z"
        fill="#C2CDDC"
      />
      <path
        d="M457.788235 591.10902H371.049412c-21.684706 0-38.550588-17.66902-38.550588-38.550589V514.007843v-1.606274c0-8.83451 7.228235-16.062745 16.062745-16.062745h131.714509c8.83451 0 16.062745 7.228235 16.062746 16.062745v40.156862c0 20.881569-17.66902 38.550588-38.550589 38.550589z m-93.163921-63.447844v24.094118c0 4.015686 3.212549 6.425098 6.425098 6.425098H457.788235c4.015686 0 6.425098-3.212549 6.425098-6.425098v-24.094118H364.624314z"
        fill="#131313"
      />
      <path
        d="M371.049412 816.790588h85.935686c12.850196 0 22.487843 10.440784 22.487843 22.487843v40.156863H348.561569v-40.156863c0-12.850196 9.637647-22.487843 22.487843-22.487843z"
        fill="#C2CDDC"
      />
      <path
        d="M480.276078 895.498039H348.561569c-8.83451 0-16.062745-7.228235-16.062745-16.062745v-40.156863c0-21.684706 17.66902-38.550588 38.550588-38.550588H457.788235c21.684706 0 38.550588 17.66902 38.550589 38.550588v40.156863c0 8.83451-7.228235 16.062745-16.062746 16.062745z m-115.651764-32.12549h99.589019v-24.094118c0-4.015686-3.212549-6.425098-6.425098-6.425098H371.049412c-4.015686 0-6.425098 3.212549-6.425098 6.425098v24.094118z"
        fill="#131313"
      />
      <path
        d="M382.293333 575.046275h64.250981v241.744313h-64.250981z"
        fill="#F3F3F3"
      />
      <path
        d="M446.544314 832.853333h-64.250981c-8.83451 0-16.062745-7.228235-16.062745-16.062745V575.046275c0-8.83451 7.228235-16.062745 16.062745-16.062746h64.250981c8.83451 0 16.062745 7.228235 16.062745 16.062746v241.744313c0 8.83451-7.228235 16.062745-16.062745 16.062745z m-48.188236-32.12549h32.125491V591.10902h-32.125491v209.618823z"
        fill="#131313"
      />
      <path
        d="M546.936471 511.598431h131.714509v40.156863c0 12.850196-10.440784 22.487843-22.487843 22.487843H569.424314c-12.850196 0-22.487843-10.440784-22.487843-22.487843v-40.156863z"
        fill="#C2CDDC"
      />
      <path
        d="M656.163137 591.10902H570.227451c-21.684706 0-38.550588-17.66902-38.550588-38.550589V514.007843v-1.606274c0-8.83451 7.228235-16.062745 16.062745-16.062745h131.71451c8.83451 0 16.062745 7.228235 16.062745 16.062745v40.156862c-0.803137 20.881569-17.66902 38.550588-39.353726 38.550589z m-93.163921-63.447844v24.094118c0 4.015686 3.212549 6.425098 6.425098 6.425098h86.738823c4.015686 0 6.425098-3.212549 6.425098-6.425098v-24.094118H562.999216z"
        fill="#131313"
      />
      <path
        d="M569.424314 816.790588h85.935686c12.850196 0 22.487843 10.440784 22.487843 22.487843v40.156863H546.936471v-40.156863c0-12.850196 10.440784-22.487843 22.487843-22.487843z"
        fill="#C2CDDC"
      />
      <path
        d="M678.65098 895.498039H546.936471c-8.83451 0-16.062745-7.228235-16.062746-16.062745v-40.156863c0-21.684706 17.66902-38.550588 38.550589-38.550588h86.738823c21.684706 0 38.550588 17.66902 38.550588 38.550588v40.156863c0 8.83451-7.228235 16.062745-16.062745 16.062745z m-115.651764-32.12549h99.589019v-24.094118c0-4.015686-3.212549-6.425098-6.425098-6.425098H569.424314c-4.015686 0-6.425098 3.212549-6.425098 6.425098v24.094118z"
        fill="#131313"
      />
      <path
        d="M580.668235 575.046275h64.250981v241.744313h-64.250981z"
        fill="#F3F3F3"
      />
      <path
        d="M644.919216 832.853333h-64.250981c-8.83451 0-16.062745-7.228235-16.062745-16.062745V575.046275c0-8.83451 7.228235-16.062745 16.062745-16.062746h64.250981c8.83451 0 16.062745 7.228235 16.062745 16.062746v241.744313c0 8.83451-7.228235 16.062745-16.062745 16.062745z m-48.188236-32.12549h32.125491V591.10902h-32.125491v209.618823z"
        fill="#131313"
      />
      <path
        d="M738.083137 511.598431h131.71451v40.156863c0 12.850196-10.440784 22.487843-22.487843 22.487843H760.57098c-12.850196 0-22.487843-10.440784-22.487843-22.487843v-40.156863z"
        fill="#C2CDDC"
      />
      <path
        d="M847.309804 591.10902H760.57098c-21.684706 0-38.550588-17.66902-38.550588-38.550589V514.007843v-1.606274c0-8.83451 7.228235-16.062745 16.062745-16.062745h131.71451c8.83451 0 16.062745 7.228235 16.062745 16.062745v40.156862c0 20.881569-17.66902 38.550588-38.550588 38.550589z m-93.163922-63.447844v24.094118c0 4.015686 3.212549 6.425098 6.425098 6.425098h86.738824c4.015686 0 6.425098-3.212549 6.425098-6.425098v-24.094118H754.145882z"
        fill="#131313"
      />
      <path
        d="M760.57098 816.790588h86.738824c12.850196 0 22.487843 10.440784 22.487843 22.487843v40.156863H738.083137v-40.156863c0-12.850196 9.637647-22.487843 22.487843-22.487843z"
        fill="#C2CDDC"
      />
      <path
        d="M869.797647 895.498039H738.083137c-8.83451 0-16.062745-7.228235-16.062745-16.062745v-40.156863c0-21.684706 17.66902-38.550588 38.550588-38.550588h86.738824c21.684706 0 38.550588 17.66902 38.550588 38.550588v40.156863c0 8.83451-7.228235 16.062745-16.062745 16.062745z m-115.651765-32.12549h99.58902v-24.094118c0-4.015686-3.212549-6.425098-6.425098-6.425098H760.57098c-4.015686 0-6.425098 3.212549-6.425098 6.425098v24.094118z"
        fill="#131313"
      />
      <path
        d="M771.814902 575.046275h64.25098v241.744313h-64.25098z"
        fill="#F3F3F3"
      />
      <path
        d="M836.065882 832.853333h-64.25098c-8.83451 0-16.062745-7.228235-16.062745-16.062745V575.046275c0-8.83451 7.228235-16.062745 16.062745-16.062746h64.25098c8.83451 0 16.062745 7.228235 16.062745 16.062746v241.744313c0 8.83451-7.228235 16.062745-16.062745 16.062745z m-48.188235-32.12549h32.12549V591.10902h-32.12549v209.618823z"
        fill="#131313"
      />
      <path
        d="M107.620392 879.435294h821.609412c12.850196 0 22.487843 10.440784 22.487843 22.487843v62.644706c0 12.850196-10.440784 22.487843-22.487843 22.487843H107.620392c-12.850196 0-22.487843-10.440784-22.487843-22.487843v-62.644706c0-12.047059 9.637647-22.487843 22.487843-22.487843z"
        fill="#EDF2F8"
      />
      <path
        d="M930.032941 1003.118431H107.620392c-21.684706 0-38.550588-17.66902-38.550588-38.550588v-62.644706c0-21.684706 17.66902-38.550588 38.550588-38.550588h821.609412c21.684706 0 38.550588 17.66902 38.550588 38.550588v62.644706c0.803137 21.684706-16.865882 38.550588-37.747451 38.550588zM107.620392 895.498039c-4.015686 0-6.425098 3.212549-6.425098 6.425098v62.644706c0 4.015686 3.212549 6.425098 6.425098 6.425098h821.609412c4.015686 0 6.425098-3.212549 6.425098-6.425098v-62.644706c0-4.015686-3.212549-6.425098-6.425098-6.425098H107.620392z"
        fill="#131313"
      />
      <path
        d="M38.550588 942.08h962.158432c12.850196 0 22.487843 10.440784 22.487843 22.487843v20.881569c0 12.850196-10.440784 22.487843-22.487843 22.487843H38.550588c-12.850196 0-22.487843-10.440784-22.487843-22.487843v-20.881569c0-12.047059 10.440784-22.487843 22.487843-22.487843z"
        fill="#EDF2F8"
      />
      <path
        d="M1001.512157 1024H38.550588c-21.684706 0-38.550588-17.66902-38.550588-38.550588v-20.881569c0-21.684706 17.66902-38.550588 38.550588-38.550588h962.158432c21.684706 0 38.550588 17.66902 38.550588 38.550588v20.881569c0.803137 21.684706-16.865882 38.550588-37.747451 38.550588zM38.550588 958.142745c-4.015686 0-6.425098 3.212549-6.425098 6.425098v20.881569c0 4.015686 3.212549 6.425098 6.425098 6.425098h962.158432c4.015686 0 6.425098-3.212549 6.425098-6.425098v-20.881569c0-4.015686-3.212549-6.425098-6.425098-6.425098H38.550588z"
        fill="#131313"
      />
    </svg>
  );
};
