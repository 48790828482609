import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MyButton } from '../../_components/reuse/my-button';
import useIdentity from '../../_store/useIdentity';
import { useBetween } from '../../hooks/useBetween';
import { MyLottie } from '../../_components/reuse/my-lottie';
// import useFirme from '../../_store/useFirme';
import { helpers } from '../../_utils/helpers';
import useApi from '../../hooks/useApi';
import useEvents from '../../_store/useEvents';
import { LabelEmail } from '../../_components/reuse/LabelEmail';
import { ForgotPasswordRequest } from './types';
import { SvgPassword } from '../../_components/svgs/svg-password';
import useTranslations from '../translations/useTranslations';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const { currentTranslation } = useBetween(useTranslations);

  // const { firme } = useBetween(useFirme);

  const { loggedUser, email, setEmail } = useBetween(useIdentity);
  const { executeMethodFromModule } = useApi();
  const { enterPressed, clearEnterPressed } = useBetween(useEvents);

  const [error, setError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [data, setData] = React.useState<ForgotPasswordRequest>({
    email: '',
    password: '',
  });

  const updateData = (value: string, key: string) => {
    setData((data: ForgotPasswordRequest) => ({ ...data, [key]: value }));
  };

  const callSendResetPasswordEmail = useCallback(async () => {
    setError('');
    const { email } = data;
    if (!email) {
      setError('INVALID_EMAIL');
      return;
    }

    if (!helpers.isValidEmail(email)) {
      setError('INVALID_EMAIL');
      return;
    }

    const responseData = await executeMethodFromModule(
      {
        method: 'forgotPassword',
        moduleName: 'user',

        body: {
          email,
        },
      },
      {
        allowAnonymous: true,
      }
    );

    helpers.checkHttpResponseForErrors(responseData);

    if (!responseData.success) {
      if (typeof responseData.message === 'string') {
        setError(responseData.message || 'Eroare la resetarea parolei');
      } else {
        setError('Eroare la resetarea parolei');
      }
      return;
    }

    setEmailSent(true);
    setError('Verificati email-ul pentru resetarea parolei');
  }, [data, executeMethodFromModule]);

  useEffect(() => {
    if (!enterPressed) {
      return;
    }
    callSendResetPasswordEmail();
    clearEnterPressed();
  }, [enterPressed, callSendResetPasswordEmail, clearEnterPressed]);

  useEffect(() => {
    if (email) {
      setData((data: ForgotPasswordRequest) => ({ ...data, email }));
    }
  }, [email]);

  // const checkShouldTriggerImport = useCallback(async () => {
  //   //
  //   const response = await getFirme({
  //     first: 0,
  //     pageNo: 0,
  //     rowsPerPage: 10,
  //   });

  //   if (!response.success) {
  //     setError(response.message);
  //     return false;
  //   }
  //   return response.data.records.length > 0;
  // }, [getFirme]);

  // useEffect(() => {
  //   if (!loggedUser) {
  //     return;
  //   }

  //   if (!firme || !firme.length) {
  //     return navigate('/start');
  //   }
  //   return navigate('/accounting');
  // }, [firme, loggedUser, navigate]);

  return !loggedUser ? (
    <>
      <div className="fcenter mt15">
        <SvgPassword></SvgPassword>
      </div>
      <div className="flex flex-column center-v">
        <div className="mt15 p10 bold" style={{ padding: '0 20px' }}>
          <span>{currentTranslation.ACCOUNT.forgotPasswordMessage}</span>
        </div>

        <div className="mt10">
          <LabelEmail
            label={currentTranslation.ACCOUNT.email}
            onChange={(val: string) => updateData(val, 'email')}
            value={data.email}
            disabled={emailSent}
          ></LabelEmail>
        </div>
        <div className="flex" style={{ marginTop: '20px' }}>
          <MyButton
            onClick={() => callSendResetPasswordEmail()}
            text={currentTranslation.ACCOUNT.resetPassword}
            disabled={emailSent}
          ></MyButton>
        </div>

        <div className="fcenter mt10">
          <MyButton
            onClick={() => navigate('/login')}
            text={currentTranslation.ACCOUNT.navigateToLogin}
            className="linkbutton ml5"
            useBaseButton={false}
          ></MyButton>
        </div>

        {error ? <div className="error mt10">{error}</div> : null}
      </div>
    </>
  ) : null;
};
