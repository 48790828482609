import GenericList from '../../../todo/list/GenericList';
import useIdentity, { ILoggedUser } from '../../../../_store/useIdentity';
import { utils } from '../../../../_utils/utils';
import observer from '../../../../_store/observer';
import AddEditMoneyEntity from '../add-edit/add-edit-money-account';
import { getDefaultMoneyEntity } from '../money-account-helpers';
import { MONEY_ACCOUNT_COLLECTION } from '../constants';
import { IMoneyAccount } from '../money-account-type';
import useMoneyAccounts from '../hooks/useMoneyAccounts';
import MyIcon from '../../../../_components/reuse/my-icon';
import { useCallback, useEffect } from 'react';
import { useBetween } from '../../../../hooks/useBetween';
import { MyButton } from '../../../../_components/reuse/my-button';
import useTranslations from '../../../translations/useTranslations';

const MoneyAccountList = () => {
  const { currentTranslation } = useBetween(useTranslations);
  const { loggedUser } = useBetween(useIdentity);
  const collectionName = MONEY_ACCOUNT_COLLECTION(loggedUser as ILoggedUser);

  const createItem = (): IMoneyAccount => getDefaultMoneyEntity(loggedUser);

  const { refreshAccounts, accounts } = useBetween(useMoneyAccounts);

  const renderAddEditContent = (
    item: IMoneyAccount,
    onSave: (item: IMoneyAccount) => Promise<unknown>,
    onCancel: () => void
  ) => {
    return (
      <AddEditMoneyEntity
        moneyEntity={item || createItem()}
        onCancel={() => {
          observer.publish('ENABLE_SHORTCUT', true);
          onCancel();
        }}
        onSave={onSave}
      ></AddEditMoneyEntity>
    );
  };

  const renderActions = (
    item: IMoneyAccount,
    setItem: any,
    setItemToBeDeleted: any
  ) => {
    if (item.userid !== loggedUser?._id) {
      return null;
    }
    return (
      <div className="fcenter">
        <div className="ml10">
          <MyIcon
            disabled={item.userid !== loggedUser?._id}
            icon="pi pi-calendar"
            tooltip="Edit"
            onClick={() => setItem(item)}
          ></MyIcon>
        </div>

        <div className="ml10">
          <MyIcon
            disabled={item.userid !== loggedUser?._id}
            icon="pi pi-trash"
            tooltip="Delete"
            onClick={() => setItemToBeDeleted(item)}
          ></MyIcon>
        </div>
      </div>
    );
  };

  const onAfterItemSaved = useCallback((item: IMoneyAccount) => {
    setTimeout(() => {
      refreshAccounts();
    }, 500);
  }, []);

  return (
    <>
      <GenericList
        renderCreateFirstItem={(onClickFunction) => (
          <div className="mt10">
            {/* {JSON.stringify(selectedFirma)} */}
            <div className="ml5">
              <MyButton
                text={currentTranslation.ACCOUNT.addAccount}
                onClick={onClickFunction}
                className="w300"
              ></MyButton>
            </div>
          </div>
        )}
        fieldHeader={[
          {
            field: 'nick',
            header: currentTranslation.HEADERS.User,
            body: (item) => item.nick,
          },
          {
            field: 'date',
            header: currentTranslation.HEADERS.Date,
            body: (item) => utils.dateNumberToYYYYMMDD(item.date),
          },
          {
            field: 'name',
            header: currentTranslation.HEADERS.Name,
          },
          {
            field: 'amount',
            header: currentTranslation.HEADERS.Amount,
          },
        ]}
        createItem={createItem}
        addItemButtonLabel="Adaugare Account"
        renderAddEditContent={renderAddEditContent}
        collectionName={collectionName}
        sortBy={[{ field: 'date', ascending: false }]}
        filterBy={{ userid: loggedUser?._id }}
        modalTitle={(item: IMoneyAccount) => {
          return item?.name
            ? `Editare Account ${item.name}`
            : 'Adaugare Account';
        }}
        onAfterItemSaved={onAfterItemSaved}
        renderActions={renderActions}
      ></GenericList>
    </>
  );
};

export default MoneyAccountList;
