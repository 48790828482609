const TR_ro = {
  INVALID_PASSWORD: 'Parola incorecta',
  USER_NOT_FOUND: 'Utilizator inexistent',
  INVALID_EMAIL: 'Email incorect',

  HEADERS: {
    User: 'Utilizator',
    Category: 'Categorie',
    Amount: 'Suma',
    Date: 'Data',
    Description: 'Descriere',
    Name: 'Nume',
    Events: 'Evenimente',
    Invitations: 'Invitatii',
    Accounts: 'Conturi',
    Money_aggregator: 'Agregator de bani',
    Logout: 'Iesire',
    Login: 'Logare',
  },
  ACCOUNT: {
    rememberUser: 'Pasteaza utilizatorul',
    navigateToCreateAccount: 'Navigate catre creare cont',
    forgotPassword: 'Am uitat parola',
    email: 'Email',
    password: 'Parola',
    forgotPasswordMessage:
      'Introduceți emailul și vom trimite un link pentru resetarea parolei',
    resetPassword: 'Resetare parola',
    navigateToLogin: 'Navigare catre logare',
    nick: 'Pseudonim',
    termsAndConditions: 'Termeni si conditoo',
    createUser: 'Creare cont',
    addAccount: 'Adauga cont',
    editAccount: 'Editare cont',
    deleteAccount: 'Stergere cont',
    invalidName: 'Nume invalid',
    account: 'Cont',
  },
  MONEY_TRANSACTIONS: {
    transactions: 'Transactiii',
    addTransaction: 'Adaugare transactie',
    editTransaction: 'Editare tranzactie',
    deleteTransaction: 'Stergere transactie',
    transactionType: 'Tip tranzactie',
    users: 'Utilizatori',
  },
  CATEGORIES: {
    categories: 'Categorii',
    addCategory: 'Adaugare categorie',
    editCategory: 'Editare categorie',
    deleteCategory: 'Stergere categorie',
  },
  SAVE: 'Salveaza',
  CANCEL: 'Renunta',
  ERROR: 'Eroare',
  SUCCESS: 'Success',
  LOADING: 'Incarcare',
  WARNING: 'Warning',
  UPDATE: 'Modificare',
  Charts: 'Charturi',
};

export default TR_ro;
