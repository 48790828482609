import { useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MyButton } from '../../_components/reuse/my-button';
import { LabelInput } from '../../_components/reuse/LabelInput';
import useIdentity from '../../_store/useIdentity';
import { useBetween } from '../../hooks/useBetween';
import { MyLottie } from '../../_components/reuse/my-lottie';
import { MyCheckbox } from '../../_components/reuse/my-checkbox';
import useFirme from '../../_store/useFirme';
import { helpers } from '../../_utils/helpers';
import useApi from '../../hooks/useApi';
import { SvgPassword } from '../../_components/svgs/svg-password';
import useTranslations from '../translations/useTranslations';

export type UserAccount = {
  email: string;
  password: string;
  nick: string;
};

export const CreateAccount = () => {
  const navigate = useNavigate();
  const { currentTranslation } = useBetween(useTranslations);
  const { firme } = useBetween(useFirme);
  const { executeMethodFromModule } = useApi();

  const [checked, setChecked] = useState(false);

  const { loggedUser, setareUserLogat } = useBetween(useIdentity);

  const [error, setError] = useState('');

  const [data, setData] = React.useState<UserAccount>({
    email: '',
    password: '',
    nick: '',
  });

  const updateData = (value: string, key: string) => {
    setData((data) => ({ ...data, [key]: value }));
  };

  const callCreateAccount = async (payload: UserAccount) => {
    setError('');
    if (!checked) {
      setError('Trebuie sa fiti de acord cu termenii si conditii');
      return;
    }
    if (!payload.nick) {
      setError('Numele trebuie sa fie completat');
      return;
    }
    if (!payload.email) {
      setError('INVALID_EMAIL');
      return;
    }

    if (!payload.password) {
      setError('INVALID_PASSWORD');
      return;
    }

    const responseData = await executeMethodFromModule(
      {
        method: 'createUser',
        moduleName: 'user',
        body: payload,
      },
      { allowAnonymous: true }
      // payload.email
      //   .replace("@", "")
      //   .replace(".", "")
      //   .replace(/[^a-zA-Z]+/g, "")
    );
    helpers.checkHttpResponseForErrors(responseData);

    if (!responseData.success) {
      if (typeof responseData.message === 'string') {
        setError(responseData.message || 'Eroare la crearea contului');
      } else {
        setError('Eroare la crearea contului');
      }

      return;
    }
    setareUserLogat(responseData.data);
  };

  // const checkShouldTriggerImport = useCallback(async () => {
  //   //
  //   const response = await getFirme({
  //     first: 0,
  //     pageNo: 0,
  //     rowsPerPage: 10,
  //   });

  //   if (!response.success) {
  //     setError(response.message);
  //     return false;
  //   }
  //   return response.data.records.length > 0;
  // }, [getFirme]);

  useEffect(() => {
    if (!loggedUser) {
      return;
    }

    if (!firme || !firme.length) {
      return navigate('/start');
    }
    return navigate('/accounting');
  }, [loggedUser, navigate, firme]);

  return !loggedUser ? (
    <>
      <div className="fcenter mt15">
        <SvgPassword></SvgPassword>
      </div>
      <div className="flex flex-column center-v">
        <div className="mt10">
          <LabelInput
            label={currentTranslation.ACCOUNT.nick}
            onChange={(val: string) => updateData(val, 'nick')}
            value={data.nick}
          ></LabelInput>
        </div>

        <div className="mt10">
          <LabelInput
            label={currentTranslation.ACCOUNT.email}
            onChange={(val: string) => updateData(val, 'email')}
            value={data.email}
          ></LabelInput>
        </div>

        <div className="mt10">
          <LabelInput
            label={currentTranslation.ACCOUNT.password}
            // type="password"
            onChange={(val: string) => updateData(val, 'password')}
            value={data.password}
          ></LabelInput>
        </div>

        <div className="mt10">
          <MyCheckbox
            id="termeni-conditii"
            label={currentTranslation.ACCOUNT.termsAndConditions}
            checked={checked}
            value={checked}
            onChange={() => setChecked(!checked)}
          ></MyCheckbox>
        </div>
        <div className="flex" style={{ marginTop: '20px' }}>
          <MyButton
            onClick={() => callCreateAccount(data)}
            text={currentTranslation.ACCOUNT.createUser}
          ></MyButton>
        </div>

        {/* <div className="fcenter mt10">
          <MyButton
            text="Logare cu Google"
            onClick={() => {
              if (!checked) {
                setError("Trebuie sa fie de acord cu termenii si conditiile");
                return;
              }
              GoogleAuth.login().then((res: any): any => {
                createGoogleUser(res);
              });
            }}
            className="linkbutton"
          ></MyButton>
        </div> */}

        <div className="fcenter mt10">
          <MyButton
            onClick={() => navigate('/login')}
            text={currentTranslation.HEADERS.Login}
            className="linkbutton ml5"
            useBaseButton={false}
          ></MyButton>
        </div>

        {error ? <div className="error mt10">{error}</div> : null}
      </div>
    </>
  ) : null;
};
